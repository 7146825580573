import React from 'react';

import Page from '../layout/Page';
import Section from "../layout/Section";
import HeroHeader from "../layout/HeroHeader";

import './Home.scss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import Subnavbar from "../layout/Subnavbar";
import SectionHeader from "../layout/SectionHeader";

class Legal extends React.Component {

    componentDidMount() {

    }
    render() {
        return (
            <Page>
                <HeroHeader className="">
                    <div className="container vhm-100 d-flex flex-column justify-content-between align-items-stretch">

                        <div className="flex-fill"/>

                        <div className="row justify-content-center align-items-center mt-6">
                            <div className="col-12">
                                <header className="text-white text-center"
                                        data-aos="fade-up"

                                >
                                    <h1>Impressum</h1>
                                </header>
                            </div>
                        </div>

                        <div className="flex-fill"/>

                        <div className="text-center mb-4">
                            <a href="#impressum"
                               className="link btn btn-lg bg-transparent text-white text-center"
                               data-scroll>
                                <div className="mb-2">
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                </div>
                                <div>Mehr Informationen</div>
                            </a>
                        </div>
                    </div>
                </HeroHeader>

                <div className="position-relative sub-navbar-wrapper">

                    <Subnavbar>
                        <div id="menu" className="nav row bg-white border-top border-bottom">
                            <a href={"/#services"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Leistungen
                            </a>
                            <a href={"/#qualification"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Qualifikationen
                            </a>
                            <a href={"/#business"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Unternehmen
                            </a>
                            <a href={"/#contact"}
                               className="nav-link col-3 p-3 lead text-center">
                                Kontakt
                            </a>
                        </div>
                    </Subnavbar>

                    <div id="content">
                        <Section id="impressum" className={"container py-6"}>
                            <SectionHeader className="" title1="Das" title2="Impressum"/>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h3 className="text-center my-4">Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                                    <p className="text-left">
                                        <strong> René Oguntke</strong><br/>
                                        Taxi Rerik &amp; Mietwagenbetrieb K&uuml;hlungsborn<br/>
                                        Leuchtturmstra&szlig;e 10<br/>
                                        18230 Ostseebad Rerik
                                    </p>
                                    <p>
                                        Telefon:<a href="tel:+491723195272"> +49 172 3195272</a><br/>
                                        E-Mail:<a
                                        href="mailto:info@chauffeur-deutschland.de"> info@chauffeur-deutschland.de</a>
                                    </p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h3 className="text-center my-4">Bildernachweis</h3>
                                    <p>
                                        Die Bilder, die hier auf dieser Website Verwendung finden stammen von:
                                        <ul>
                                            <li><a href="https://unsplash.com">Unsplash.com (Externer Link)</a></li>
                                        </ul>
                                    </p>

                                    <p><strong>Liste</strong></p>
                                    <ul>
                                        <li>
                                            Abschnitt Qualifikation BMW Bild <br/>
                                            <a href="https://unsplash.com/@willpower?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                               className=""
                                               target="_blank"
                                               rel="noopener noreferrer external nofollow"
                                               title="Download free do whatever you want high-resolution photos from William Stitt">
                                                William Stitt von Unsplash (Externer Link)
                                            </a>
                                        </li>
                                        <li>
                                            Abschnitt Qualifikation Audi Bild <br/>
                                            <a href="https://unsplash.com/@ivancortez14?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                               className=""
                                               target="_blank"
                                               rel="noopener noreferrer external nofollownoopener noreferrer"
                                               title="Download free do whatever you want high-resolution photos from Ivan Cortez">
                                                Ivan Cortez von Unsplash (Externer Link)
                                            </a>
                                        </li>
                                        <li>
                                            Abschnitt Zahlen Hintergrundbild<br/>
                                            <a href="https://unsplash.com/@flo_stk?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                               className=""
                                               target="_blank"
                                               rel="noopener noreferrer external nofollownoopener noreferrer"
                                               title="Download free do whatever you want high-resolution photos from Florian Steciuk">
                                                Florian Steciuk von Unsplash (Externer Link)
                                            </a>
                                        </li>
                                        <li>
                                            Abschnitt Kontakt Hintergrundbild<br/>
                                            <a href="https://unsplash.com/@alexandreboucher?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                               className=""
                                               target="_blank"
                                               rel="noopener noreferrer external nofollownoopener noreferrer"
                                               title="Download free do whatever you want high-resolution photos from Alexandre Boucher">
                                                Alexandre Boucher von Unsplash (Externer Link)
                                            </a>
                                        </li>
                                        <li>
                                            Hintergrundbild<br/>
                                            <a href="https://unsplash.com/@jack_anstey?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                                               className=""
                                               target="_blank"
                                               rel="noopener noreferrer external nofollownoopener noreferrer"
                                               title="Download free do whatever you want high-resolution photos from Jack Anstey">
                                                Jack Anstey von Unsplash (Externer Link)
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h3 className="text-center my-4">Umsatzsteuer</h3>
                                    <p className="text-left">
                                        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a
                                        Umsatzsteuergesetz:
                                        <strong> DE245229954</strong>
                                    </p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h3 className="text-center my-4">Umsetzung</h3>
                                    <p>
                                        Die Umsetzung der Webseite wurde durch <a
                                        href="https://www.xing.com/profile/William_Reiher"
                                        rel="noopener noreferrer external nofollownoopener noreferrer"
                                        title="Umsetzung durch William Reiher">
                                        William Reiher (Externer Link)
                                    </a> ausgef&uuml;hrt.
                                    </p>
                                </div>
                            </div>

                            <h3 className="text-center mt-6 mb-4">Streitschlichtung</h3>
                            <p>
                                Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
                                bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank"
                                           rel="noopener noreferrer external nofollownoopener">
                                https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse finden Sie oben im
                                Impressum.
                                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.
                            </p>

                            <h3 className="text-center mt-6 mb-4">Haftung f&uuml;r Inhalte</h3>
                            <p>
                                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte
                                auf
                                diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10
                                TMG
                                sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
                                gespeicherte
                                fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf
                                eine
                                rechtswidrige T&auml;tigkeit hinweisen.
                            </p>
                            <p>
                                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                                allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung
                                ist
                                jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich.
                                Bei
                                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                                entfernen.
                            </p>

                            <h3 className="text-center mt-6 mb-4">Haftung f&uuml;r Links</h3>
                            <p>
                                Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir
                                keinen
                                Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
                                Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der
                                jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                                wurden
                                zum Zeitpunkt der Verlinkung auf m&ouml;gliche
                                Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt
                                der
                                Verlinkung nicht erkennbar.
                            </p>
                            <p>
                                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                                Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                            </p>

                            <h3 className="text-center mt-6 mb-4">Urheberrecht</h3>
                            <p>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                                dem
                                deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art
                                der
                                Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der
                                schriftlichen
                                Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                                nur
                                f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
                            </p>
                            <p>
                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                                werden
                                wir derartige Inhalte umgehend entfernen.
                            </p>

                            <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
                        </Section>
                    </div>
                </div>

            </Page>
        );
    }
}

export default Legal;