import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

import './Contact.scss';
import Section from "../layout/Section";
import SectionHeader from "../layout/SectionHeader";

class Contact extends React.Component {
    render() {
        return (
            <Section id="contact" className={'bg-dark text-white p-2 p-lg-6 ' + (this.props.className ? this.props.className: "")}>

                <SectionHeader className="" title1="Die" title2="Kontaktdaten">
                    Sicheren Sie sich einen unserer Dienste. Sie erhalten von uns ein <strong
                    className="font-weight-bold">kostenloses</strong> und <strong
                    className="font-weight-bold">unverbindlich</strong> Angebot.
                </SectionHeader>

                {/* Kontakte */}
                <div className="row">
                    <div className="col-md-6 col-sm-12"
                         data-aos="zoom-out">
                        <a className="link-hero"
                           href="tel:+491723195272">
                            <div>
                                <div className="d-flex justify-content-center pb-4">
                                    <div className="p-4 p-sm-5 border rounded-circle icon">
                                        <FontAwesomeIcon className="" size="3x" icon={faPhone}/>
                                    </div>
                                </div>
                                {/*<a className="btn btn-link btn-lg button-hero w-100 mb-4"*/}
                                <div className="number w-100 mb-4">
                                    +49 172 3195272
                                </div>
                            </div>
                        </a>
                        <div className="text-white h6 mt-2 text-center">Jetzt anrufen!</div>
                    </div>
                    <div className="col-md-6 col-sm-12"
                         data-aos="zoom-out">
                        <a className="link-hero"
                           href="mailto:info@chauffeur-deutschland.de">
                            <div>
                                <div className="d-flex justify-content-center pb-4">
                                    <div className="p-4 p-sm-5 border rounded-circle icon">
                                        <FontAwesomeIcon className="" size="3x" icon={faEnvelope}/>
                                    </div>
                                </div>
                                {/*<a className="btn btn-link btn-lg button-hero w-100 mb-4"*/}
                                <div className="number w-100 mb-4">
                                    info@chauffeur-deutschland.de
                                </div>
                            </div>
                        </a>
                        <div className="text-white h6 mt-2 text-center">Jetzt schreiben!</div>
                    </div>
                </div>

            </Section>
        );
    }
}

export default Contact;