import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

import Section from "../layout/Section";

class ContactSmall extends React.Component {
    render() {
        return (
            <Section id="small_contact"
                     className={"bg-gradient " + (this.props.className ? this.props.className: "")}>

                <div className="container">
                    <div className="text-center text-white lead mb-3">
                        Interesse geweckt? Dann kontaktieren Sie uns und wir machen Ihnen ein unverbindliches
                        kostenloses Angebot.
                    </div>

                    {/*<button className="btn btn-primary">Kontakt</button>*/}

                    <div className="row">
                        <div className="col-md-6 col-sm-12 mb-3 mb-md-0"
                             data-aos="zoom-out">
                            <a className="link-hero small"
                               href="tel:+491723195272">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="mr-3 p-3 border rounded-circle icon d-none d-md-block">
                                        <FontAwesomeIcon className="" size="2x" icon={faPhone}/>
                                    </div>
                                    <div className="number">
                                        +49 172 3195272
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6 col-sm-12"
                             data-aos="zoom-out">
                            <a className="link-hero small"
                               href="mailto:info@chauffeur-deutschland.de">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="mr-3 p-3 border rounded-circle icon d-none d-md-block">
                                        <FontAwesomeIcon className="" size="2x" icon={faEnvelope}/>
                                    </div>
                                    <div className="number">
                                        info@chauffeur-deutschland.de
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Section>
        );
    }
}

export default ContactSmall;