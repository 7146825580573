import React from 'react';

class SectionHeader extends React.Component {

    render() {
        return (
            <div className={"container p-0 pb-5 " + (this.props.className ? this.props.className: "")} data-aos="zoom-out">
                <h2 className={this.props.children ? 'section-header h1 px-0 m-0 text-center pb-5' : 'section-header h1 px-0 m-0 text-center'}>
                    {this.props.title1} <span className="highlight py-2">{this.props.title2}</span>
                </h2>

                {this.props.children !== null ?
                    <p className="text-center">
                        {this.props.children}
                    </p>
                    : ""
                }
            </div>
        );
    }
}

export default SectionHeader;