import React from 'react';

import './Qualifications.scss';

import Section from "../layout/Section";
import SectionHeader from "../../components/layout/SectionHeader";

import BMWImage from '../../assets/img/bmw.jpg';
import AudiImage from '../../assets/img/audi.jpg';

class Qualifications extends React.Component {

    componentDidMount() {

    }

    render() {
        return (
            <Section id="qualification" className={"container " + (this.props.className ? this.props.className: "")}>
                <SectionHeader className="" title1="Unsere" title2="Qualifizierungen">
                    Um Ihnen eine angenehme Fahrt zu gewährleisten und Sie sicher an ihr Ziel zu bringen, stehen für uns
                    hohe Fahrsicherheitsanforderungen mit an erster Stelle.
                </SectionHeader>

                <div className="row align-items-center mb-6" data-aos="fade-right">
                    <div className="col-12 col-lg-6">
                        <img src={BMWImage} className="img-fluid" alt="BMW Driving Experience"/>
                        <a href="https://unsplash.com/@willpower?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                           className="d-block small text-black-50 text-right my-2"
                           target="_blank"
                           rel="noopener noreferrer external nofollownoopener noreferrer"
                           title="Download free do whatever you want high-resolution photos from William Stitt">
                            Foto von William Stitt von Unsplash (Externer Link)
                        </a>
                    </div>
                    <div className="col-12 col-lg-6 pt-4 pt-lg-0 text-center text-lg-left">
                        <h3 className="py-3 px-0 px-lg-3 mb-0 mb-lg-3">BMW Driving Experience.</h3>

                        <p className="py-3 px-0 px-lg-3">
                            Die BMW Driving Experience ist ein zertifiziertes Trainingsprogramm von BMW, welches eine
                            Vielzahl von verschiedenen Trainingsvarianten anbietet. Mithilfe des Trainingsprogramms
                            können wir unsere fahrerischen Fähigkeiten kontinuierlich verbessern und so Ihnen eine
                            bequeme und sichere Fahrt ermöglichen.
                        </p>

                        <a href={"https://www.bmw-drivingexperience.com/de/trainings/bmw-driving-experience.html"}
                           className="m-3">Mehr erfahren (Externer Link)</a>
                    </div>
                </div>

                <div className="row align-items-center" data-aos="fade-left">
                    <div className="col-12 col-lg-6 order-0 order-lg-1">
                        <img src={AudiImage} className="img-fluid" alt="BMW Driving Experience"/>
                        <a href="https://unsplash.com/@ivancortez14?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                           className="d-block small text-black-50 text-right my-2"
                           target="_blank"
                           rel="noopener noreferrer external nofollownoopener noreferrer"
                           title="Download free do whatever you want high-resolution photos from Ivan Cortez">
                            Foto von Ivan Cortez von Unsplash (Externer Link)
                        </a>
                    </div>

                    <div className="col-12 col-lg-6 pt-4 pt-lg-0 text-center text-lg-right order-0">
                        <h3 className="py-3 px-0 px-lg-3 mb-0 mb-lg-3">Audi Driving Experience.</h3>

                        <p className="py-3 px-0 px-lg-3">
                            Da wir uns nicht nur auf ein Fahr- und Sicherheitstraining verlassen wollen, wird zusätzlich
                            das Trainingsprogamm Audi Driving Experience in regelmäßigen Abständen absolviert. Dieses
                            bietet umfangreiche Möglichkeiten uns fahrerischen Fähigkeiten weiter zu optimieren und Sie
                            so sicher ans Ziel zu bringen.
                        </p>

                        <a href={"https://www.audi.de/de/brand/de/audi-driving-experience-sommer.html"}
                           className="m-3">Mehr erfahren (Externer Link)</a>
                    </div>
                </div>

            </Section>
        );
    }
}

export default Qualifications;