import React from 'react';

import Section from "../layout/Section";
import SectionHeader from "../../components/layout/SectionHeader";
import ReneImage from "../../assets/img/rene.jpg";

class Qualifications extends React.Component {

    componentDidMount() {

    }

    render() {
        return (
            <Section id="business" className={"container " + (this.props.className ? this.props.className: "")}>
                <SectionHeader className="" title1="Das" title2="Unternehmen">
                </SectionHeader>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <img src={ReneImage} className="img-fluid" alt="BMW Driving Experience"/>
                    </div>

                    <div className="col-md-6 col-sm-12 pt-4 pt-lg-0 text-center text-lg-left">
                        <h3>René Oguntke</h3>
                        Ich bin René Oguntke und Geschäftsführer dieses Chauffeurdienstes. Ich bin seit 1993
                        professionell als Chauffeur tätig und seit 2000 selbständiger Unternehmer. Sie als Kunde stehen
                        im Mittelpunkt meines Unternehmens. Darum ist es mir wichtig, dass Sie zufrieden sind. Weiterhin
                        bilde ich mich ständig weiter, um Ihnen immer eine sichere und angenehme Fahrt zu gewähren.
                    </div>
                </div>
            </Section>
        );
    }
}

export default Qualifications;