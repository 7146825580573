import React from 'react';

import Page from '../layout/Page';
import Subnavbar from "../layout/Subnavbar";

import './Home.scss';

import Services from "../sections/Services";
import Qualifications from "../sections/Qualifications";
import HomeHeader from "../sections/HomeHeader";
import Business from "../sections/Business";
import Contact from "../sections/Contact";
import ContactSmall from "../sections/ContactSmall";
import Certifications from "../sections/Certifications";

class Home extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <Page>
                <HomeHeader/>
                <div className="position-relative sub-navbar-wrapper">
                    <Subnavbar>
                        <div id="menu" className="nav row bg-white border-top border-bottom">
                            <a href={"#services"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Leistungen
                            </a>
                            <a href={"#business"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Unternehmen
                            </a>
                            <a href={"#qualification"}
                               className="nav-link col-3 p-3 lead text-center border-right">
                                Qualifikationen
                            </a>
                            <a href={"#contact"}
                               className="nav-link col-3 p-3 lead text-center">
                                Kontakt
                            </a>
                        </div>
                    </Subnavbar>

                    <div id="content">
                        <Services className="pt-6"/>
                        <ContactSmall className="py-4"/>
                        <Business className="py-6"/>
                        <hr/>
                        <Qualifications className="py-6"/>
                        <hr/>
                        <Certifications  className="py-6"/>
                        <Contact className="py-6"/>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Home;