import React from 'react';
import Section from './Section';

import './Subnavbar.scss';

import stickybits from "stickybits/dist/stickybits.min";

class Subnavbar extends React.Component {

    componentDidMount() {
        stickybits('#sub-navbar', {
            useStickyClasses: true,
            stickyBitStickyOffset: document.getElementById("navbar").offsetHeight,
        });
    }

    render() {
        return (
            <Section id={"sub-navbar"} className={"container-fluid w-100 d-none d-sm-block"}>
                {this.props.children}
            </Section>
        );
    }
}

export default Subnavbar;