import React from 'react';

import './Services.scss';

import Section from "../layout/Section";
import ServiceElement from "../../components/sections/ServiceElement";
import SectionHeader from "../../components/layout/SectionHeader";

import {faCar} from "@fortawesome/free-solid-svg-icons";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faHandHoldingHeart} from "@fortawesome/free-solid-svg-icons";
import {faPeopleCarry} from "@fortawesome/free-solid-svg-icons";
import {faMapSigns} from "@fortawesome/free-solid-svg-icons";

class Services extends React.Component {

    componentDidMount() {
        const elements = document.getElementsByClassName("visible-scroll");
        const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        window.addEventListener('scroll', function () {
            for (let i = 0; i < elements.length; i++) {
                const elCoord = elements[i].getBoundingClientRect();

                if (elCoord.top > (h / 2 - elCoord.height) && elCoord.bottom < (h / 2 + elCoord.height)) {
                    elements[i].classList.add("active");
                } else {
                    elements[i].classList.remove("active");
                }
            }
        });

    }

    render() {
        return (
            <Section id="services" className={(this.props.className ? this.props.className: "")}>
                <SectionHeader className="" title1="Unsere" title2="Leistungen">
                    Wir bieten Ihnen umfangreiche Dienstleistungen im Bereich der Personenbef&ouml;rderung und
                    Betreuung an. Sie profitieren dabei auf eine individuelle Betreuung, die genau auf ihre
                    Bed&uuml;rfnisse und W&uuml;nsche zugeschnitten ist.
                </SectionHeader>

                <div className="">
                    <ServiceElement number="1)" clientId="chauffeur" title="Allgemein" icon={faCar} reverse={true}>
                        Wir fahren Sie bundesweit zu jedem gew&uuml;nschten Ziel. Wichtige Termine, Hochzeiten
                        oder andere Gelegenheiten spielen f&uuml;r uns keine Rolle. Diskretion und ihr
                        Wohlbefinden hat stets h&ouml;chste Priorit&auml;t. W&auml;hrend wir Sie sicher zum Zielort
                        fahren, k&ouml;nnen
                        Sie sich entspannen oder ihren wichtigen Aufgaben widmen.
                    </ServiceElement>

                    <ServiceElement number="2)" clientId="hochzeiten" title="Hochzeiten" icon={faHeart}
                                    reverse={false}>
                        Alle Vorbereitungen abgeschlossen? Das Geschenk zur Hochzeit ist eingepackt, die Frisur sitzt?
                        Jetzt fehlt nur noch der Chauffeur, der Sie nach ihren individuellen Wünschen zum Altar fährt.
                        Ob Mietwagen oder ihren eigenen Auto, wir bringen Sie sicher zu ihrer Hochzeit.
                    </ServiceElement>

                    <ServiceElement number="3)" clientId="senioren" title="Senioren" icon={faHandHoldingHeart}
                                    reverse={true}>
                        Sie dürfen nicht mehr Auto fahren? Sie fühlen sich nicht mehr sicher? Wir begleiten Sie
                        überall hin. Ob mit ihren eigenen oder unseren Fahrzeugen, fahren wir sie zum Einkaufen, zum
                        Arzt, in den Urlaub, zur Behörde oder zu ihren Liebsten. Wir begleiten Sie sicher zu ihren
                        Wunschziel.
                    </ServiceElement>

                    <ServiceElement number="4)" clientId="kunde" title="Geschäftskunden" icon={faUserTie}
                                    reverse={false}>
                        Sie wollen ihren Kunden einen schnellen und unkomplizierten Transfer bieten? Unser
                        qualifizierten Mitarbeiter fahren Sie diskret und sicher zu ihren gewünschten Zielen. Egal ob es
                        sich nur um einen einfachen Transfer vom und zum Flughafen handelt oder Sie ihren Kunden einen
                        Shuttle Service anbieten möchten.
                    </ServiceElement>

                    <ServiceElement number="5)" clientId="Others" title="Urlaubstransfer"
                                    icon={faMapSigns} reverse={true}>
                        Sie sind in Urlaubsstimmung? Sie möchten Sightseeing-Touren durch die Stadt oder durch das Land
                        machen? Wir fahren Sie überall hin, egal ob regional, bundesweit oder international, egal ob
                        Berlin, Hambrug, München pder Stuttgart. Wir bringen Sie in ihre Wunschregion.
                    </ServiceElement>

                    <ServiceElement number="6)" clientId="Others" title="Behindertenfreundlich"
                                    icon={faHandHoldingHeart} reverse={false}>
                        Sie sind an den Rollstuhl gebunden? Sie haben ein Handicap? Dies ist für uns kein Problem.
                        Unsere Fahrzeuge sind behindertenfreundlich ausgestattet und garantieren Ihnen einen einfachen
                        Einstieg als auch eine bequeme Fahrt. Bei genaueren Fragen kontaktieren Sie uns einfach, wir
                        beatworten gerne ihre Fragen.
                    </ServiceElement>

                    <ServiceElement number="7)" clientId="Others" beforeTitle="Weitere" title="Leistungen"
                                    icon={faPeopleCarry} reverse={true}>
                        Falls Sie sich keiner der genannten Leistungen wiederfinden, können Sie uns gerne kontaktieren.
                        Egal ob per Mail oder Telefon, wir sind immer für Sie erreichbar.
                    </ServiceElement>
                </div>
            </Section>
        );
    }
}

export default Services;