import React from 'react';

import './Footer.scss';

class Footer extends React.Component {
    render() {
        return (
            <footer id="footer" className={'navbar-dark bg-dark ' + (this.props.className ? this.props.className: "")}>
                <div className={"container-fluid bg-darker"}>
                    <div className="row p-2 text-white-50 align-items-center">
                        <div className="col-12 col-lg-4 small text-center text-lg-left">
                            Designed by William Reiher
                        </div>
                        <div className={"col-12 col-lg-4 text-center p-3 py-lg-0"}>
                            <a className={"pr-3"} href={"/"}>Home</a>
                            <a className={"pr-3"} href={"/datenschutz"}>Datenschutz</a>
                            <a className={""} href={"/impressum"}>Impressum</a>
                        </div>
                        < div className="col-12 col-lg-4 small text-center text-lg-right">
                            © Copyright 2018 René Oguntke. Alle Rechte vorbehalten.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;