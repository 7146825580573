import React, {Component} from 'react';

import Home from './components/pages/Home';
import Privacy from "./components/pages/Privacy";
import Legal from "./components/pages/Legal";

import AOS from "aos";
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min.js';
import {Switch, Route, BrowserRouter} from "react-router-dom";

class App extends Component {

    componentDidMount() {
        AOS.init({
            duration: 400,
            easing: 'ease-in-out'
        });

        new SmoothScroll('a[href*="#"]', {
            offset: function (anchor, toggle) {
                return document.getElementById('navbar').offsetHeight;
            }
        });
    }

    UNSAFE_componentWillReceiveProps() {
        AOS.refresh();
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/impressum" component={Legal}/>
                    <Route exact path="/datenschutz" component={Privacy}/>
                    <Route path="*" component={Home}/>
                </Switch>
            </BrowserRouter>
        );
    }
};

export default App;