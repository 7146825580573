import React from 'react';

import './Certifications.scss';

import Section from "../layout/Section";

import BMW2008_Certificate from "../../assets/img/zertifikate/bmw_zertifikat_2008.jpg";
import BMW2012_Certificate from "../../assets/img/zertifikate/bmw_zertifikat_2012.jpg";
import BMW2013_Certificate from "../../assets/img/zertifikate/bmw_zertifikat_2013.jpg";
import BMW2018_Certificate from "../../assets/img/zertifikate/bmw_zertifikat_2018.jpg";
import Audi2010_1_Certificate from "../../assets/img/zertifikate/audi_zertifikat_2010_1.jpg";
import Audi2010_2_Certificate from "../../assets/img/zertifikate/audi_zertifikat_2010_2.jpg";
import Audi2014_Certificate from "../../assets/img/zertifikate/audi_zertifikat_2014.jpg";
import Audi2015_Certificate from "../../assets/img/zertifikate/audi_zertifikat_2015.jpg";
import Audi2016_Certificate from "../../assets/img/zertifikate/audi_zertifikat_2016.jpg";

import SectionHeader from "../layout/SectionHeader";

import Slider from "react-slick";

class Certifications extends React.Component {

    slider_settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    componentDidMount() {

    }

    render() {
        return (
            <Section id="certifications"
                     className={"" + (this.props.className ? this.props.className : "")}>
                <SectionHeader className="" title1="Die " title2="Zertifikate">
                    Hier sehen Sie eine Auswahl unser Zertifikate für die absolvierten Fahr- und Sicherheitsprogramme.
                </SectionHeader>
                <Slider {...this.slider_settings}>
                    <img className="img-thumbnail img-certificate" src={BMW2008_Certificate}
                         alt="Fahrsicherheitszertifikat von BMW - 2008"/>
                    <img className="img-thumbnail img-certificate" src={BMW2012_Certificate}
                         alt="Fahrsicherheitszertifikat von BMW - 2012"/>
                    <img className="img-thumbnail img-certificate" src={BMW2013_Certificate}
                         alt="Fahrsicherheitszertifikat von BMW - 2013"/>
                    <img className="img-thumbnail img-certificate" src={BMW2018_Certificate}
                         alt="Fahrsicherheitszertifikat von BMW - 2018"/>
                    <img className="img-thumbnail img-certificate" src={Audi2010_1_Certificate}
                         alt="Fahrsicherheitszertifikat von Audi - 2010"/>
                    <img className="img-thumbnail img-certificate" src={Audi2010_2_Certificate}
                         alt="Fahrsicherheitszertifikat von Audi - 2010"/>
                    <img className="img-thumbnail img-certificate" src={Audi2014_Certificate}
                         alt="Fahrsicherheitszertifikat von Audi - 2014"/>
                    <img className="img-thumbnail img-certificate" src={Audi2015_Certificate}
                         alt="Fahrsicherheitszertifikat von Audi - 2015"/>
                    <img className="img-thumbnail img-certificate" src={Audi2016_Certificate}
                         alt="Fahrsicherheitszertifikat von Audi - 2016"/>
                </Slider>
            </Section>
        );
    }
}

export default Certifications;