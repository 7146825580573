import React from 'react';
import Section from "./Section";

import './HeroHeader.scss';

class HeroHeader extends React.Component {
    render() {
        return (
            <Section id="header"
                     className={"vhm-100 d-flex " + (this.props.className ? this.props.className: "")}>
                {this.props.children}
            </Section>
        );
    }
}

export default HeroHeader;