import React from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

class Page extends React.Component {

    render() {
        return (
            <div className="page">
                <Navbar className=""/>

                <main id="mainContent" className="flex-grow-1">
                    {this.props.children}
                </main>

                <Footer className=""/>
            </div>
        );
    }
}

export default Page;