import React from 'react';

import HeroHeader from "../layout/HeroHeader";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

class HomeHeader extends React.Component {

    componentDidMount() {

    }

    render() {
        return (
            <HeroHeader className="">
                <div className="container vhm-100 d-flex flex-column justify-content-between align-items-stretch">

                    <div className="flex-fill"/>

                    <div className="row justify-content-center align-items-center mt-6 mb-3">
                        <div className="col-12">
                            <header className="text-white text-center"
                                    data-aos="fade-up">
                                <h1 className="display-4 mb-5">
                                    <span className="py-3">Sie suchen </span>
                                    <span className="py-3 animated-words text-center text-sm-left">
                                        <b className="animated-words-element"><span className="highlight">einen Chauffeurdienst?</span></b>
                                        <b className="animated-words-element"><span className="highlight">einen Butler Service?</span></b>
                                        <b className="animated-words-element"><span className="highlight">einen Begleitschutz?</span></b>
                                        <b className="animated-words-element"><span className="highlight">andere Fahrservices?</span></b>
                                        <b className="invisible placeholder"
                                           aria-hidden="true">andere Fahrservices?</b>
                                    </span>
                                </h1>

                                <p className="lead mb-5 text-center">
                                    Dann sind Sie bei uns genau richtig! Wir fahren Sie bequem und zuverl&auml;ssig zu
                                    Ihrem Wunschziel. W&uuml;nschen Sie mehr als nur eine einfache Taxifahrt? Neben
                                    dem <b>Chauffeurdienst</b> bieten wir Ihnen viele weitere Leistungen an. Hierzu
                                    finden Sie weiter unten einen Überblick. Zusätzlich sind wir ein tierfreundliches
                                    Unternehmen - Ihre Lieblinge sind für uns kein Problem.
                                </p>

                                <p className="h3 mb-5 text-center">
                                    Kontaktieren Sie uns direkt unter
                                </p>

                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12">
                                        <a className="link-hero" href="tel:+491723195272">
                                            <div>
                                                <div className="d-flex justify-content-center pb-4">
                                                    <div className="p-4 p-sm-5 border rounded-circle icon">
                                                        <FontAwesomeIcon className="" size="3x" icon={faPhone}/>
                                                    </div>
                                                </div>
                                                {/*<a className="btn btn-link btn-lg button-hero w-100 mb-4"*/}
                                                <div className="number w-100 mb-4">
                                                    +49 172 3195272
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <a className="link-hero" href="mailto:info@chauffeur-deutschland.de">
                                            <div>
                                                <div className="d-flex justify-content-center pb-4">
                                                    <div className="p-4 p-sm-5 border rounded-circle icon">
                                                        <FontAwesomeIcon className="" size="3x" icon={faEnvelope}/>
                                                    </div>
                                                </div>
                                                {/*<a className="btn btn-link btn-lg button-hero w-100 mb-4"*/}
                                                <div className="number w-100 mb-4">
                                                    info@chauffeur-deutschland.de
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </header>
                        </div>
                    </div>

                    <div className="flex-fill"/>

                    <div className="text-center mb-4">
                        <a href="#services"
                           className="link btn btn-lg bg-transparent text-white text-center"
                           data-scroll>
                            <div className="mb-2">
                                <FontAwesomeIcon icon={faArrowDown}/>
                            </div>
                            <div>Mehr Informationen</div>
                        </a>
                    </div>
                </div>
            </HeroHeader>
        );
    }
}

export default HomeHeader;