import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import logoDark from '../../assets/img/brands/ogentke_logo_dunkel_transparent3.png';
import logoLight from '../../assets/img/brands/ogentke_logo_hell_transparent3.png';

import "./Navbar.scss";

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_scroll: false
        };
    }

    componentDidMount() {
        let self = this;

        window.addEventListener('scroll', function () {
            const scrollY = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            self.setState({is_scroll: scrollY > 1});
        });
    }

    toggleMenu() {
        document.getElementById("smallMenu").classList.toggle("active");
        document.getElementById("mainContent").classList.toggle("overflow-hidden");
    }

    render() {
        return (
            <div>
                <nav id="navbar"
                     className={'navbar navbar-expand-lg fixed-top ' + (this.props.className ? this.props.className : '') + ' ' + (this.state.is_scroll ? 'navbar-light bg-white' : 'navbar-dark bg-transparent')}>
                    <a className="navbar-brand mr-auto" href="/">
                        {this.state.is_scroll ? (
                            <img src={logoLight} alt="Firmenlogo" width="auto" height="40"
                                 className="d-inline-block align-top"/>
                        ) : (
                            <img src={logoDark} alt="Firmenlogo" width="auto" height="40"
                                 className="d-inline-block align-top"/>
                        )}
                    </a>

                    <ul className="d-none d-sm-flex flex-row navbar-nav">
                        <li className="nav-item mr-2">
                            <a className={"nav-link " + (this.state.is_scroll ? '' : 'text-white')}
                               href="tel:+491723195272">
                                <FontAwesomeIcon className="mr-2" icon={faPhone}/>
                                +49 172 3195272
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link " + (this.state.is_scroll ? '' : 'text-white')}
                               href="mailto:info@chauffeur-deutschland.de">
                                <FontAwesomeIcon className="mr-2" icon={faEnvelope}/>
                                info@chauffeur-deutschland.de
                            </a>
                        </li>
                    </ul>

                    <button
                        id="menuButton"
                        type="button"
                        className={"d-block d-sm-none btn btn-link " + (this.state.is_scroll ? "text-darker" : "text-white")}
                        onClick={this.toggleMenu}>
                        <span className="icon"><FontAwesomeIcon icon={faBars}/></span>
                    </button>
                </nav>

                <div id="smallMenu" tabIndex="-1">
                    <div className="h-100 d-flex flex-column flex-nowrap">
                        <div className="navbar navbar-expand-lg">
                            <div className="navbar-brand mr-auto">
                                <img src={logoDark} alt="Firmenlogo" width="auto" height="40"
                                     className="d-inline-block align-top"/>
                            </div>

                            <button
                                id="menuOpenButton"
                                type="button"
                                className={"active btn btn-link text-white"}
                                onClick={this.toggleMenu}>
                                <span className="icon"><FontAwesomeIcon icon={faTimes}/></span>
                            </button>
                        </div>

                        <div className="flex-grow-1 d-flex flex-column flex-nowrap justify-content-between"
                             style={{overflowX: 'hidden'}}>
                            <div className="container py-4 px-2">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a className={"nav-link text-white"}
                                               href="tel:+491723195272">
                                                <FontAwesomeIcon className="mr-2" icon={faPhone}/>
                                                +49 172 3195272
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a className={"nav-link text-white"}
                                               href="mailto:info@chauffeur-deutschland.de">
                                                <FontAwesomeIcon className="mr-2" icon={faEnvelope}/>
                                                info@chauffeur-deutschland.de
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container py-4 px-2">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"#services"}
                                               onClick={this.toggleMenu}
                                               className="nav-link text-white">
                                                Leistungen
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"#qualification"}
                                               onClick={this.toggleMenu}
                                               className="nav-link text-white">
                                                Qualifikationen
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"#business"}
                                               onClick={this.toggleMenu}
                                               className="nav-link text-white">
                                                Unternehmen
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"#contact"}
                                               onClick={this.toggleMenu}
                                               className="nav-link text-white">
                                                Kontakt
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container py-4 px-2">
                                <div className="row text-center">
                                    <div className="col-12 nav-item">
                                        <a href={"/"}
                                           className="nav-link text-white">
                                            Home
                                        </a>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"/datenschutz"}
                                               className="nav-link text-white">
                                                Datenschutz
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nav-item">
                                            <a href={"/impressum"}
                                               className="nav-link text-white">
                                                Impressum
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;