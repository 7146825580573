import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './ServiceElement.scss';

class ServiceElement extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div id={this.props.clientId} className="visible-scroll">
                <div className="container" data-aos={this.props.reverse === true ? "fade-left" : "fade-right"}>
                    <div className="row py-5">
                        <div className="number order-0 d-none d-lg-block">
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <span className="display-4">{this.props.number}</span>
                            </div>
                        </div>
                        <div
                            className={"col-12 col-md-6 col-lg-4 d-none d-md-flex justify-content-center " + (this.props.reverse === true ? 'order-1' : 'order-0 offset-0 offset-lg-2')}>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <div className="p-4 p-md-5 border rounded-circle icon">
                                    <FontAwesomeIcon icon={this.props.icon} size={"4x"}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"col-12 col-md-6 col-lg-4 text-center text-md-left " + (this.props.reverse === true ? 'order-0 offset-0 offset-lg-2' : 'order-1')}>
                            <h3 className="py-3 mb-3">
                                {this.props.beforeTitle} <span className="highlight py-2">{this.props.title}</span>
                            </h3>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceElement;