import React from 'react';

class Section extends React.Component {

    render() {
        return (
            <div id={this.props.id} className={"section " + (this.props.className ? this.props.className: "")} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }

}

export default Section;